.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.loginForm {
	display: flex;
	flex-direction: column;
	width: 400px;
	margin: 0 auto;
	margin-top: 50px;
}

.loginForm--title {
	text-decoration: underline;
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 20px;
}

.loginForm--actions {
	justify-content: center;
}

.loginForm--input {
	margin-top: 20px !important;
	min-width: 300px !important;
}

.loginForm--btn {
	margin-top: 20px !important;
	background-color: #000352 !important;
	color: #ffffff !important;
}

.MuiInputLabel-formControl {
	left: 10px !important;
	margin-top: 5px;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
	.MuiTableSortLabel-icon {
	color: #ffffff !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
	color: #ffffff !important;
}

.MuiTableSortLabel-root:hover {
	color: #ffffff !important;
}

.MuiIconButton-root:hover {
	background-color: unset !important;
}

.MuiList-padding {
	padding-top: 20px !important;
}

.MuiTableSortLabel-root {
	padding-bottom: 5px !important;
}

.MuiTableCell-paddingCheckbox:last-child {
	padding-left: 4px !important;
	padding-right: 4px !important;
}
